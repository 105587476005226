<template>
  <div class="article">
    <article
      class="tn-cube-box paper dock-loader atom-data-binding ng-scope tn-from-house-reader_booklet-cp tn-comp-anim-pin tn-comp-inst tn-cube-box-inst tn-comp tn-in-cell-state-active"
      ng-show="!htmlForPreview"
      tn-bind-comp-tpl-id="reader_booklet-cp:sys/loader-cubes-box"
      tn-comp-role="cube-box"
      tn-comp="box"
      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
      tn-uuid=""
      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
      opera-tn-ra-comp="box"
      style=""
    >
      <section
        class="tn-comp-pin tn-comp-style-pin"
        tn-link="compAttr.link || comp.link"
        tn-sound="compAttr.sound"
        ng-style="compAttr.style | optimizeImageType"
        tn-pre-load-image="compAttr.style.backgroundImage"
      >
        <div
          class="tn-cube-slot tn-view-cubes tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-state-active tn-cube-flipper-box"
          tn-cell-type="group"
          tn-cell="cubes"
          tn-link="cell.link"
          ng-style="cell.style | optimizeImageType"
          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
          tn-animate-on-self="true"
          tn-sound="cell.sound"
          ng-class="{ 'group-empty': !(cellGroupItems.length) }"
          opera-tn-ra-cell="box.cubes"
          style=""
        >
          <!-- ngRepeat: chd in cellGroupItems track by $index -->
          <div
            class="tn-surface tn-cube tn-show-cube ng-scope tn-from-house-reader_booklet-cp tn-comp-anim-pin tn-comp-inst tn-cube-inst tn-comp tn-in-cell-state-active on-screen"
            tn-comp-role="cube"
            tn-bind-comp-tpl-id="reader_booklet-cp:sys/cube-fs"
            ng-repeat="chd in cellGroupItems track by $index"
            tn-comp="chd"
            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
            tn-uuid=""
            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
            tn-comp-index="0"
            opera-tn-ra-comp="box.cubes:0"
            style=""
          >
            <section
              class="tn-comp-pin tn-comp-style-pin"
              tn-link="compAttr.link || comp.link"
              tn-sound="compAttr.sound"
              ng-style="compAttr.style | optimizeImageType"
              tn-pre-load-image="compAttr.style.backgroundImage"
            >
              <div
                class="dock booklet tn-ground-slot tn-view-grounds tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-state-active tn-vertical-flipper-box"
                tn-cell-type="group"
                tn-cell="grounds"
                tn-link="cell.link"
                ng-style="cell.style | optimizeImageType"
                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                tn-animate-on-self="true"
                tn-sound="cell.sound"
                ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                opera-tn-ra-cell="box.cubes:0.grounds"
                style=""
              >
                <!-- ngRepeat: chd in cellGroupItems track by $index -->
                <div
                  class="tn-surface tn-ground tn-show-ground ng-scope tn-from-house-reader_booklet-cp tn-comp-anim-pin tn-comp-inst tn-ground-inst tn-comp tn-in-cell-state-active tn-comp-mode-board on-screen"
                  tn-comp-role="ground"
                  tn-bind-comp-tpl-id="reader_booklet-cp:sys/pg-fs"
                  ng-repeat="chd in cellGroupItems track by $index"
                  tn-comp="chd"
                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                  tn-uuid=""
                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                  tn-comp-index="0"
                  opera-tn-ra-comp="box.cubes:0.grounds:0"
                  style=""
                >
                  <section
                    class="tn-comp-pin tn-comp-style-pin"
                    tn-link="compAttr.link || comp.link"
                    tn-sound="compAttr.sound"
                    ng-style="compAttr.style | optimizeImageType"
                    tn-pre-load-image="compAttr.style.backgroundImage"
                  >
                    <div
                      class="tn-layer-slot tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-state-active"
                      tn-cell-type="group"
                      tn-cell="layers"
                      tn-link="cell.link"
                      ng-style="cell.style | optimizeImageType"
                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                      tn-animate-on-self="true"
                      tn-sound="cell.sound"
                      ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                      opera-tn-ra-cell="box.cubes:0.grounds:0.layers"
                      style=""
                    >
                      <!-- ngRepeat: chd in cellGroupItems track by $index -->
                      <div
                        class="tn-layer ng-scope tn-from-house-reader_booklet-cp tn-comp-anim-pin tn-comp-inst tn-layer-inst tn-comp tn-in-cell-state-active"
                        tn-comp-role="layer"
                        tn-bind-comp-tpl-id="reader_booklet-cp:sys/ly-fs"
                        ng-repeat="chd in cellGroupItems track by $index"
                        tn-comp="chd"
                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                        tn-uuid=""
                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                        tn-comp-index="0"
                        opera-tn-ra-comp="box.cubes:0.grounds:0.layers:0"
                        style=""
                      >
                        <section
                          class="tn-comp-pin tn-comp-style-pin"
                          tn-link="compAttr.link || comp.link"
                          tn-sound="compAttr.sound"
                          ng-style="compAttr.style | optimizeImageType"
                          tn-pre-load-image="compAttr.style.backgroundImage"
                        >
                          <div
                            class="tn-comp-slot tn-cell tn-cell-group tn-child-position-absolute group-empty tn-state-active ng-scope"
                            tn-cell-type="group"
                            tn-link="cell.link"
                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                            tn-animate-on-self="true"
                            tn-ra-wrapper-cell=""
                            tn-ra-cell="box.cubes:0.grounds:0.layers:0.comps"
                          ></div>
                        </section>
                      </div>
                      <!-- end ngRepeat: chd in cellGroupItems track by $index -->
                    </div>
                  </section>
                </div>
                <!-- end ngRepeat: chd in cellGroupItems track by $index -->
              </div>
              <div
                class="dock booklet tn-page-slot tn-view-pages tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-state-active tn-vertical-flipper-box"
                tn-cell-type="group"
                tn-cell="pages"
                tn-link="cell.link"
                ng-style="cell.style | optimizeImageType"
                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                tn-animate-on-self="true"
                tn-sound="cell.sound"
                ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                opera-tn-ra-cell="box.cubes:0.pages"
                style=""
              >
                <!-- ngRepeat: chd in cellGroupItems track by $index -->
                <div
                  class="tn-surface tn-page tn-show-page ng-scope tn-from-house-reader_booklet-cp tn-comp-anim-pin tn-comp-inst tn-page-inst tn-comp tn-in-cell-state-active tn-comp-mode-flow on-screen"
                  tn-comp-role="page"
                  tn-bind-comp-tpl-id="reader_booklet-cp:sys/pg-fs"
                  ng-repeat="chd in cellGroupItems track by $index"
                  tn-comp="chd"
                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                  tn-uuid=""
                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                  tn-comp-index="0"
                  opera-tn-ra-comp="box.cubes:0.pages:0"
                  style=""
                >
                  <section
                    class="tn-comp-pin tn-comp-style-pin"
                    tn-link="compAttr.link || comp.link"
                    tn-sound="compAttr.sound"
                    ng-style="compAttr.style | optimizeImageType"
                    tn-pre-load-image="compAttr.style.backgroundImage"
                    style="background-color: rgb(185, 217, 235)"
                  >
                    <div
                      class="tn-layer-slot tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-state-active"
                      tn-cell-type="group"
                      tn-cell="layers"
                      tn-link="cell.link"
                      ng-style="cell.style | optimizeImageType"
                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                      tn-animate-on-self="true"
                      tn-sound="cell.sound"
                      ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                      opera-tn-ra-cell="box.cubes:0.pages:0.layers"
                      style=""
                    >
                      <!-- ngRepeat: chd in cellGroupItems track by $index -->
                      <div
                        class="tn-layer ng-scope tn-from-house-reader_booklet-cp tn-comp-anim-pin tn-comp-inst tn-layer-inst tn-comp tn-in-cell-state-active"
                        tn-comp-role="layer"
                        tn-bind-comp-tpl-id="reader_booklet-cp:sys/ly-fs"
                        ng-repeat="chd in cellGroupItems track by $index"
                        tn-comp="chd"
                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                        tn-uuid=""
                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                        tn-comp-index="0"
                        opera-tn-ra-comp="box.cubes:0.pages:0.layers:0"
                        style=""
                      >
                        <section
                          class="tn-comp-pin tn-comp-style-pin"
                          tn-link="compAttr.link || comp.link"
                          tn-sound="compAttr.sound"
                          ng-style="compAttr.style | optimizeImageType"
                          tn-pre-load-image="compAttr.style.backgroundImage"
                        >
                          <div
                            class="tn-comp-slot tn-cell tn-cell-group tn-child-position-absolute tn-state-active ng-scope"
                            tn-cell-type="group"
                            tn-link="cell.link"
                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                            tn-animate-on-self="true"
                            tn-ra-wrapper-cell=""
                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps"
                          >
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:0"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="text-align: justify"
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:0.txt1"
                                >
                                  <p><br /></p>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:1"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="
                                    color: rgb(0, 133, 202);
                                    font-size: 225%;
                                    text-shadow: rgb(255, 255, 255) 4px 0px;
                                    text-align: center;
                                    letter-spacing: 4px;
                                  "
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:1.txt1"
                                >
                                  <p><strong>战略合作企业</strong></p>
                                  <p><strong>（空岛科技）简介</strong></p>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                                style="
                                  text-align: center;
                                  margin-top: 10px;
                                  margin-bottom: 10px;
                                "
                              >
                                <div
                                  tn-cell-type="image"
                                  style="
                                    max-width: 100%;
                                    vertical-align: middle;
                                    display: inline-block;
                                    line-height: 0;
                                    width: 50%;
                                    height: auto;
                                  "
                                  class="tn-cell tn-cell-image tn-state-active ng-scope"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  title=""
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.img1"
                                >
                                  <img
                                    class="tn-image-presenter raw-image"
                                    src="//cdn.yueshare.cn/mlab/666b9783b3010c0f0119a320bced513c-sz_30347.png"
                                    style="
                                      vertical-align: middle;
                                      max-width: 100%;
                                      width: 100%;
                                    "
                                  />
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="text-align: justify"
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.txt1"
                                >
                                  <p>&#8203;</p>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                                style="
                                  display: flex;
                                  flex-flow: row nowrap;
                                  margin: 10px 0% 20px;
                                  transform: translate3d(20px, 0px, 0px);
                                "
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    vertical-align: bottom;
                                    width: auto;
                                    flex: 0 0 0%;
                                    height: auto;
                                    align-self: flex-end;
                                    line-height: 0;
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: perspective(0px) rotateZ(90deg);
                                      transform-style: flat;
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: right;
                                        transform: rotateY(180deg);
                                        justify-content: flex-end;
                                      "
                                    >
                                      <div
                                        style="
                                          display: inline-block;
                                          width: 22px;
                                          height: 22px;
                                          vertical-align: top;
                                          overflow: hidden;
                                          border-width: 1px;
                                          border-radius: 50%;
                                          border-style: solid;
                                          border-color: rgb(0, 133, 202);
                                          background-color: rgba(
                                            255,
                                            255,
                                            255,
                                            0
                                          );
                                          line-height: 0;
                                        "
                                        tn-cell-type="group"
                                        tn-child-orientation="fixed"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style="
                                            position: static;
                                            transform: rotateZ(315deg);
                                          "
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="
                                              text-align: center;
                                              justify-content: center;
                                              margin: 9px 0% 0px;
                                              transform: translate3d(
                                                3px,
                                                0px,
                                                0px
                                              );
                                            "
                                          >
                                            <div
                                              style="
                                                display: inline-block;
                                                width: 8px;
                                                height: 8px;
                                                vertical-align: top;
                                                overflow: hidden;
                                                border-style: solid none none
                                                  solid;
                                                border-width: 1px;
                                                border-color: rgb(0, 133, 202)
                                                  rgb(255, 255, 255)
                                                  rgb(255, 255, 255)
                                                  rgb(0, 133, 202);
                                              "
                                              tn-cell-type="group"
                                              tn-child-orientation="fixed"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-frozen ng-scope"
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:0.col1"
                                            ></div>
                                          </section>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                                <div
                                  style="
                                    display: inline-block;
                                    vertical-align: bottom;
                                    width: auto;
                                    flex: 100 100 0%;
                                    align-self: flex-end;
                                    height: auto;
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col2"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col2:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                    >
                                      <div
                                        style="
                                          text-align: justify;
                                          color: rgb(113, 197, 232);
                                          letter-spacing: 12px;
                                          font-size: 75%;
                                          padding: 0px 13px;
                                        "
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col2:0.txt1"
                                      >
                                        <p style="text-align: left">
                                          INTRODUCE
                                        </p>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                                style="
                                  margin: 10px 0%;
                                  transform: translate3d(10px, 0px, 0px);
                                "
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: 95%;
                                    vertical-align: top;
                                    background-color: rgb(249, 244, 241);
                                    height: auto;
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        display: flex;
                                        flex-flow: row nowrap;
                                        margin: -10px 0% 90px;
                                      "
                                    >
                                      <div
                                        style="
                                          display: inline-block;
                                          width: auto;
                                          vertical-align: top;
                                          flex: 100 100 0%;
                                          align-self: flex-start;
                                          height: auto;
                                          margin: 0px 0px 0px 20px;
                                          border-left: 1px solid
                                            rgb(0, 133, 202);
                                          border-bottom-left-radius: 0px;
                                        "
                                        tn-cell-type="group"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col1:0.col1"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style="position: static"
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col1:0.col1:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="
                                              text-align: center;
                                              justify-content: center;
                                              margin: 25px 0% -75px;
                                              display: flex;
                                              flex-flow: row nowrap;
                                              transform: translate3d(
                                                -5px,
                                                0px,
                                                0px
                                              );
                                            "
                                          >
                                            <div
                                              style="
                                                display: inline-block;
                                                width: 85%;
                                                vertical-align: top;
                                                flex: 0 0 auto;
                                                height: auto;
                                                align-self: flex-start;
                                                margin: 0px;
                                              "
                                              tn-cell-type="group"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col1:0.col1:0.col1"
                                            >
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col1:0.col1:0.col1:0"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                  style="margin: 0px 0%"
                                                >
                                                  <div
                                                    style="
                                                      padding: 0px;
                                                      color: rgb(0, 75, 135);
                                                      font-size: 93.75%;
                                                      line-height: 1.8;
                                                      letter-spacing: 1px;
                                                      text-align: justify;
                                                    "
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col1:0.col1:0.col1:0.txt1"
                                                  >
                                                    <p>
                                                      空岛信息科技（上海）有限公司，成立于2016年是一家AR/VR企业级技术研发专业服务机构。核心团队来自上海交大，上海水晶石，腾讯，嘉会医院，奥美。公司致力于将增强现实（AR）与虚拟现实（VR）技术更好的应用和服务于医疗、工业及教育行业，针对行业技能实训，现场作业，人才教育培养、技能水平提升、远程协同操作，自主研发并拥有成熟落地解决方案。作企业级虚拟现实技术领域领军企业，与华为VR
                                                      ILab建立合作关系，在行业应用与5G技术结合前沿领域深耕，拥有一批自主研发技术产品，是目前国内专业、领先的企业级混合现实（XR）技术研发团队和行业合作伙伴之一。
                                                    </p>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="text-align: justify"
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.txt1"
                                >
                                  <p><br /></p>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: 100%;
                                    vertical-align: top;
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(18deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin: 0px 0px 10px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:0.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/0e659da457639384f765838385ddae64-sz_313.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(43deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:1"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin-top: 10px;
                                        margin-bottom: 10px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:1.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/3a31a9b5fa96af9c100d56053e4fb030-sz_308.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(25deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:2"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin: 10px 0px 0px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:2.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/2be29ae5c82c94d3af9d4783045077e5-sz_308.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: 100%;
                                    vertical-align: top;
                                    background-color: rgba(253, 249, 249, 0);
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        margin: 30px 0% 10px;
                                        text-align: center;
                                        transform: translate3d(1px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        style="
                                          color: rgb(255, 255, 255);
                                          font-size: 150%;
                                          line-height: 1.2;
                                          letter-spacing: 4px;
                                          padding: 0px;
                                          text-align: center;
                                          text-shadow: rgb(0, 133, 202) 3px -3px,
                                            rgb(0, 133, 202) 3px 3px,
                                            rgb(0, 133, 202) -3px 3px,
                                            rgb(0, 133, 202) -3px -3px,
                                            rgb(0, 133, 202) 3px 0px,
                                            rgb(0, 133, 202) 0px 3px,
                                            rgb(0, 133, 202) -3px 0px,
                                            rgb(0, 133, 202) 0px -3px;
                                        "
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.txt1"
                                      >
                                        <p><strong>医疗行业解决方案</strong></p>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:1"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        margin: 10px 0% -60px;
                                        text-align: center;
                                        transform: translate3d(1px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        style="
                                          text-align: center;
                                          color: rgb(255, 255, 255);
                                          font-size: 125%;
                                          line-height: 1.2;
                                          letter-spacing: 4px;
                                          padding: 0px;
                                          text-shadow: rgb(128, 182, 230) 3px -3px,
                                            rgb(128, 182, 230) 3px 3px,
                                            rgb(128, 182, 230) -3px 3px,
                                            rgb(128, 182, 230) -3px -3px,
                                            rgb(128, 182, 230) 3px 0px,
                                            rgb(128, 182, 230) 0px 3px,
                                            rgb(128, 182, 230) -3px 0px,
                                            rgb(128, 182, 230) 0px -3px;
                                        "
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:1.txt1"
                                      >
                                        <p><br /></p>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        justify-content: center;
                                      "
                                    >
                                      <div
                                        style="
                                          display: inline-block;
                                          width: 94%;
                                          vertical-align: top;
                                          height: auto;
                                          border-width: 3px;
                                          border-radius: 20px;
                                          border-style: dashed;
                                          border-color: rgb(255, 255, 255);
                                          overflow: hidden;
                                          padding: 10px 0px;
                                        "
                                        tn-cell-type="group"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style="position: static"
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="
                                              text-align: center;
                                              justify-content: center;
                                            "
                                          >
                                            <div
                                              style="
                                                display: inline-block;
                                                width: 94%;
                                                vertical-align: top;
                                                height: auto;
                                                padding: 10px;
                                                background-color: rgb(
                                                  255,
                                                  255,
                                                  255
                                                );
                                                border-width: 0px;
                                                border-radius: 20px;
                                                border-style: none;
                                                border-color: rgb(62, 62, 62);
                                                overflow: hidden;
                                              "
                                              tn-cell-type="group"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1"
                                            >
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style=""
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:0"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="text-align: justify"
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:0.txt1"
                                                  >
                                                    <p><br /></p>
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:1"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                  style="
                                                    text-align: center;
                                                    margin: 10px 0px -50px;
                                                    transform: translate3d(
                                                      1px,
                                                      0px,
                                                      0px
                                                    );
                                                  "
                                                >
                                                  <div
                                                    tn-cell-type="image"
                                                    style="
                                                      vertical-align: middle;
                                                      display: inline-block;
                                                      line-height: 0;
                                                      width: 70%;
                                                      height: auto;
                                                      border-style: solid;
                                                      border-width: 5px;
                                                      border-color: rgb(
                                                        113,
                                                        197,
                                                        232
                                                      );
                                                    "
                                                    class="tn-cell tn-cell-image tn-state-active ng-scope"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    title=""
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:1.img1"
                                                  >
                                                    <img
                                                      class="tn-image-presenter raw-image"
                                                      src="//cdn.yueshare.cn/mlab/8b890701da8b3578f6baef1652306936-sz_692036.png"
                                                      style="
                                                        vertical-align: middle;
                                                        max-width: 100%;
                                                        width: 100%;
                                                      "
                                                    />
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:2"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="
                                                      display: inline-block;
                                                      width: 100%;
                                                      vertical-align: top;
                                                      background-color: rgb(
                                                        185,
                                                        217,
                                                        235
                                                      );
                                                      border-top-left-radius: 100%;
                                                      border-top: 0px none
                                                        rgb(62, 62, 62);
                                                      overflow: hidden;
                                                      border-right: 0px none
                                                        rgb(62, 62, 62);
                                                      border-top-right-radius: 0%;
                                                      border-bottom-right-radius: 100%;
                                                      border-bottom: 2px solid
                                                        rgb(113, 197, 232);
                                                    "
                                                    tn-cell-type="group"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:2.col1"
                                                  ></div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style=""
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:3"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="text-align: justify"
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:3.txt1"
                                                  >
                                                    <p><br /></p>
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                  style="
                                                    text-align: left;
                                                    justify-content: flex-start;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      display: inline-block;
                                                      width: 100%;
                                                      vertical-align: top;
                                                    "
                                                    tn-cell-type="group"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1"
                                                  >
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                        style="
                                                          margin: 10px 0% 0px;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: auto;
                                                            vertical-align: top;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>01</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: auto;
                                                            vertical-align: top;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:0.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >行业痛点</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p
                                                                              style=""
                                                                            >
                                                                              <span
                                                                                style=""
                                                                                >●</span
                                                                              >医疗专家资源稀缺
                                                                            </p>
                                                                            <p
                                                                              style=""
                                                                            >
                                                                              ●医师交流缺乏高效沟通
                                                                            </p>
                                                                            <p
                                                                              style=""
                                                                            >
                                                                              ●也域性医疗培养资源分配不均衡
                                                                            </p>
                                                                            <p
                                                                              style=""
                                                                            >
                                                                              ●医师普遍培养周期长,培训成本高昂
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: auto;
                                                            vertical-align: top;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>02</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: auto;
                                                            vertical-align: top;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:2.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >"慧眼"模拟医学仿真实训系统</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p>
                                                                              ●远程直播,专家远程指导
                                                                            </p>
                                                                            <p>
                                                                              ●考学并用,满足日常自助学习
                                                                            </p>
                                                                            <p>
                                                                              ●长期使用可模拟培养医师临床思维
                                                                            </p>
                                                                            <p>
                                                                              ●降低医技训练成本,提供标准训练
                                                                            </p>
                                                                            <p>
                                                                              ●典型病例库,提高医师病例阅历
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: auto;
                                                            vertical-align: top;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>03</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: auto;
                                                            vertical-align: top;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:4.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >核心价值</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p>
                                                                              ●高效转化专家临床病理经验
                                                                            </p>
                                                                            <p>
                                                                              ●缓解地域医师培养资源不均衡
                                                                            </p>
                                                                            <p>
                                                                              ●提高医师培训效率,拓展病理知识
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style=""
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:6"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            text-align: justify;
                                                          "
                                                          tn-cell-type="text"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                          title=""
                                                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.col1:0.col1:4.col1:6.txt1"
                                                        >
                                                          <p><br /></p>
                                                        </div>
                                                      </section>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(346deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:3"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: right;
                                        margin: -30px 0px;
                                        justify-content: flex-end;
                                        transform: translate3d(-10px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 20%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:3.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 222 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/0023098870c2dab7542827db355809cf-sz_1086.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style=""
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:4"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                    >
                                      <div
                                        style="text-align: justify"
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:4.txt1"
                                      >
                                        <p><br /></p>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:9"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="text-align: justify"
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:9.txt1"
                                >
                                  <p><br /></p>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: 100%;
                                    vertical-align: top;
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(18deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin: 0px 0px 10px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/0e659da457639384f765838385ddae64-sz_313.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(43deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:1"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin-top: 10px;
                                        margin-bottom: 10px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:1.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/3a31a9b5fa96af9c100d56053e4fb030-sz_308.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(25deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:2"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin: 10px 0px 0px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:2.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/2be29ae5c82c94d3af9d4783045077e5-sz_308.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: 100%;
                                    vertical-align: top;
                                    background-color: rgba(253, 249, 249, 0);
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        margin: 30px 0% 10px;
                                        text-align: center;
                                        transform: translate3d(1px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        style="
                                          text-align: center;
                                          color: rgb(255, 255, 255);
                                          font-size: 150%;
                                          line-height: 1.2;
                                          letter-spacing: 4px;
                                          padding: 0px;
                                          text-shadow: rgb(0, 133, 202) 3px -3px,
                                            rgb(0, 133, 202) 3px 3px,
                                            rgb(0, 133, 202) -3px 3px,
                                            rgb(0, 133, 202) -3px -3px,
                                            rgb(0, 133, 202) 3px 0px,
                                            rgb(0, 133, 202) 0px 3px,
                                            rgb(0, 133, 202) -3px 0px,
                                            rgb(0, 133, 202) 0px -3px;
                                        "
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:0.txt1"
                                      >
                                        <p><strong>治疗制造解决方案</strong></p>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:1"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        margin: 10px 0% -60px;
                                        text-align: center;
                                        transform: translate3d(1px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        style="
                                          text-align: center;
                                          color: rgb(255, 255, 255);
                                          font-size: 125%;
                                          line-height: 1.2;
                                          letter-spacing: 4px;
                                          padding: 0px;
                                          text-shadow: rgb(128, 182, 230) 3px -3px,
                                            rgb(128, 182, 230) 3px 3px,
                                            rgb(128, 182, 230) -3px 3px,
                                            rgb(128, 182, 230) -3px -3px,
                                            rgb(128, 182, 230) 3px 0px,
                                            rgb(128, 182, 230) 0px 3px,
                                            rgb(128, 182, 230) -3px 0px,
                                            rgb(128, 182, 230) 0px -3px;
                                        "
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:1.txt1"
                                      >
                                        <p><br /></p>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        justify-content: center;
                                      "
                                    >
                                      <div
                                        style="
                                          display: inline-block;
                                          width: 94%;
                                          vertical-align: top;
                                          height: auto;
                                          border-width: 3px;
                                          border-radius: 20px;
                                          border-style: dashed;
                                          border-color: rgb(255, 255, 255);
                                          overflow: hidden;
                                          padding: 10px 0px;
                                        "
                                        tn-cell-type="group"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style="position: static"
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="
                                              text-align: center;
                                              justify-content: center;
                                            "
                                          >
                                            <div
                                              style="
                                                display: inline-block;
                                                width: 94%;
                                                vertical-align: top;
                                                height: auto;
                                                padding: 10px;
                                                background-color: rgb(
                                                  255,
                                                  255,
                                                  255
                                                );
                                                border-width: 0px;
                                                border-radius: 20px;
                                                border-style: none;
                                                border-color: rgb(62, 62, 62);
                                                overflow: hidden;
                                              "
                                              tn-cell-type="group"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1"
                                            >
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style=""
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:0"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="text-align: justify"
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:0.txt1"
                                                  >
                                                    <p><br /></p>
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:1"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                  style="
                                                    text-align: center;
                                                    margin: 10px 0px -50px;
                                                    transform: translate3d(
                                                      1px,
                                                      0px,
                                                      0px
                                                    );
                                                  "
                                                >
                                                  <div
                                                    tn-cell-type="image"
                                                    style="
                                                      max-width: 100%;
                                                      vertical-align: middle;
                                                      display: inline-block;
                                                      line-height: 0;
                                                      width: 70%;
                                                      height: auto;
                                                      border-style: solid;
                                                      border-width: 5px;
                                                      border-color: rgb(
                                                        113,
                                                        197,
                                                        232
                                                      );
                                                    "
                                                    class="tn-cell tn-cell-image tn-state-active ng-scope"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    title=""
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:1.img1"
                                                  >
                                                    <img
                                                      class="tn-image-presenter raw-image"
                                                      src="//cdn.yueshare.cn/mlab/79c91a31b126b72c5da549a2b5c8b1dd-sz_817722.jpg"
                                                      style="
                                                        vertical-align: middle;
                                                        max-width: 100%;
                                                        width: 100%;
                                                      "
                                                    />
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:2"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="
                                                      display: inline-block;
                                                      width: 100%;
                                                      vertical-align: top;
                                                      background-color: rgb(
                                                        185,
                                                        217,
                                                        235
                                                      );
                                                      border-top-left-radius: 100%;
                                                      border-top: 0px none
                                                        rgb(62, 62, 62);
                                                      overflow: hidden;
                                                      border-right: 0px none
                                                        rgb(62, 62, 62);
                                                      border-top-right-radius: 0%;
                                                      border-bottom-right-radius: 100%;
                                                      border-bottom: 2px solid
                                                        rgb(113, 197, 232);
                                                    "
                                                    tn-cell-type="group"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:2.col1"
                                                  ></div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style=""
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:3"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="text-align: justify"
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:3.txt1"
                                                  >
                                                    <p><br /></p>
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                  style="
                                                    text-align: left;
                                                    justify-content: flex-start;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      display: inline-block;
                                                      width: 100%;
                                                      vertical-align: top;
                                                    "
                                                    tn-cell-type="group"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1"
                                                  >
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                        style="
                                                          margin: 10px 0% 0px;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>01</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:0.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >行业痛点</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p>
                                                                              ●传统实训占用生产产线,消耗大量运营成本
                                                                            </p>
                                                                            <p>
                                                                              ●员工流动性产线实操经验难以固化
                                                                            </p>
                                                                            <p>
                                                                              ●传统低效制造急需数字化升级改造
                                                                            </p>
                                                                            <p>
                                                                              ●手工装配导致工作压力大,错误率高
                                                                            </p>
                                                                            <p>
                                                                              ●传统设计仿真周期长,花费高
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>02</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:2.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >"慧眼"智慧工业仿真实训系统</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p>
                                                                              ●数据可记录,智能推荐适配岗位
                                                                            </p>
                                                                            <p>
                                                                              ●不占用多余任何产线与耗材
                                                                            </p>
                                                                            <p>
                                                                              ●7*24小时随时随地使用
                                                                            </p>
                                                                            <p>
                                                                              ●智能化辅助提示装配
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>03</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:4.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >核心价值</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p>
                                                                              ●降低操作人员培训、认知成本
                                                                            </p>
                                                                            <p>
                                                                              ●提高实际工作效率和生产力
                                                                            </p>
                                                                            <p>
                                                                              ●辅助员工能力转型,能力提升
                                                                            </p>
                                                                            <p>
                                                                              ●优化员工培养计划
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style=""
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:6"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            text-align: justify;
                                                          "
                                                          tn-cell-type="text"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                          title=""
                                                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:2.col1:0.col1:4.col1:6.txt1"
                                                        >
                                                          <p><br /></p>
                                                        </div>
                                                      </section>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(346deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:3"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: right;
                                        margin: -30px 0px;
                                        justify-content: flex-end;
                                        transform: translate3d(-10px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 20%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:3.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 222 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/0023098870c2dab7542827db355809cf-sz_1086.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style=""
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:4"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                    >
                                      <div
                                        style="text-align: justify"
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:4.txt1"
                                      >
                                        <p><br /></p>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="text-align: justify"
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.txt1"
                                >
                                  <p><br /></p>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: 100%;
                                    vertical-align: top;
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(18deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin: 0px 0px 10px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1:0.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/0e659da457639384f765838385ddae64-sz_313.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(43deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col1:1"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin-top: 10px;
                                        margin-bottom: 10px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1:1.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/3a31a9b5fa96af9c100d56053e4fb030-sz_308.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(25deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col1:2"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        margin: 10px 0px 0px;
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 5%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1:2.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 137 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/2be29ae5c82c94d3af9d4783045077e5-sz_308.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: 100%;
                                    vertical-align: top;
                                    background-color: rgba(253, 249, 249, 0);
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        margin: 30px 0% 10px;
                                        text-align: center;
                                        transform: translate3d(1px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        style="
                                          text-align: center;
                                          color: rgb(255, 255, 255);
                                          font-size: 150%;
                                          line-height: 1.2;
                                          letter-spacing: 4px;
                                          padding: 0px;
                                          text-shadow: rgb(0, 133, 202) 3px -3px,
                                            rgb(0, 133, 202) 3px 3px,
                                            rgb(0, 133, 202) -3px 3px,
                                            rgb(0, 133, 202) -3px -3px,
                                            rgb(0, 133, 202) 3px 0px,
                                            rgb(0, 133, 202) 0px 3px,
                                            rgb(0, 133, 202) -3px 0px,
                                            rgb(0, 133, 202) 0px -3px;
                                        "
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.txt1"
                                      >
                                        <p><strong>教育行业解决方案</strong></p>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:1"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        margin: 10px 0% -60px;
                                        text-align: center;
                                        transform: translate3d(1px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        style="
                                          text-align: center;
                                          color: rgb(255, 255, 255);
                                          font-size: 125%;
                                          line-height: 1.2;
                                          letter-spacing: 4px;
                                          padding: 0px;
                                          text-shadow: rgb(128, 182, 230) 3px -3px,
                                            rgb(128, 182, 230) 3px 3px,
                                            rgb(128, 182, 230) -3px 3px,
                                            rgb(128, 182, 230) -3px -3px,
                                            rgb(128, 182, 230) 3px 0px,
                                            rgb(128, 182, 230) 0px 3px,
                                            rgb(128, 182, 230) -3px 0px,
                                            rgb(128, 182, 230) 0px -3px;
                                        "
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:1.txt1"
                                      >
                                        <p><br /></p>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: center;
                                        justify-content: center;
                                      "
                                    >
                                      <div
                                        style="
                                          display: inline-block;
                                          width: 94%;
                                          vertical-align: top;
                                          height: auto;
                                          border-width: 3px;
                                          border-radius: 20px;
                                          border-style: dashed;
                                          border-color: rgb(255, 255, 255);
                                          overflow: hidden;
                                          padding: 10px 0px;
                                        "
                                        tn-cell-type="group"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style="position: static"
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="
                                              text-align: center;
                                              justify-content: center;
                                            "
                                          >
                                            <div
                                              style="
                                                display: inline-block;
                                                width: 94%;
                                                vertical-align: top;
                                                height: auto;
                                                padding: 10px;
                                                background-color: rgb(
                                                  255,
                                                  255,
                                                  255
                                                );
                                                border-width: 0px;
                                                border-radius: 20px;
                                                border-style: none;
                                                border-color: rgb(62, 62, 62);
                                                overflow: hidden;
                                              "
                                              tn-cell-type="group"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1"
                                            >
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style=""
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:0"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="text-align: justify"
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:0.txt1"
                                                  >
                                                    <p><br /></p>
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:1"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                  style="
                                                    text-align: center;
                                                    margin: 10px 0px -50px;
                                                    transform: translate3d(
                                                      1px,
                                                      0px,
                                                      0px
                                                    );
                                                  "
                                                >
                                                  <div
                                                    tn-cell-type="image"
                                                    style="
                                                      max-width: 100%;
                                                      vertical-align: middle;
                                                      display: inline-block;
                                                      line-height: 0;
                                                      width: 70%;
                                                      height: auto;
                                                      border-style: solid;
                                                      border-width: 5px;
                                                      border-color: rgb(
                                                        113,
                                                        197,
                                                        232
                                                      );
                                                    "
                                                    class="tn-cell tn-cell-image tn-state-active ng-scope"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    title=""
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:1.img1"
                                                  >
                                                    <img
                                                      class="tn-image-presenter raw-image"
                                                      src="//cdn.yueshare.cn/mlab/a65960a475d247213d3a7438549f4374-sz_1274066.png"
                                                      style="
                                                        vertical-align: middle;
                                                        max-width: 100%;
                                                        width: 100%;
                                                      "
                                                    />
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:2"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="
                                                      display: inline-block;
                                                      width: 100%;
                                                      vertical-align: top;
                                                      background-color: rgb(
                                                        185,
                                                        217,
                                                        235
                                                      );
                                                      border-top-left-radius: 100%;
                                                      border-top: 0px none
                                                        rgb(62, 62, 62);
                                                      overflow: hidden;
                                                      border-right: 0px none
                                                        rgb(62, 62, 62);
                                                      border-top-right-radius: 0%;
                                                      border-bottom-right-radius: 100%;
                                                      border-bottom: 2px solid
                                                        rgb(113, 197, 232);
                                                    "
                                                    tn-cell-type="group"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:2.col1"
                                                  ></div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style=""
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:3"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                >
                                                  <div
                                                    style="text-align: justify"
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:3.txt1"
                                                  >
                                                    <p><br /></p>
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style="position: static"
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4"
                                              >
                                                <section
                                                  class="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link || comp.link"
                                                  style="
                                                    text-align: left;
                                                    justify-content: flex-start;
                                                  "
                                                >
                                                  <div
                                                    style="
                                                      display: inline-block;
                                                      width: 100%;
                                                      vertical-align: top;
                                                    "
                                                    tn-cell-type="group"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1"
                                                  >
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                        style="
                                                          margin: 10px 0% 0px;
                                                        "
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>01</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:0.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >行业痛点</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:1.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p
                                                                              style=""
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  letter-spacing: 1.6px;
                                                                                "
                                                                                >●</span
                                                                              ><span
                                                                                style="
                                                                                  letter-spacing: 1.6px;
                                                                                "
                                                                                >教育长期投入量大</span
                                                                              >
                                                                            </p>
                                                                            <p>
                                                                              ●培训效率参差不齐
                                                                            </p>
                                                                            <p>
                                                                              ●经验丰富教育专家紧缺
                                                                            </p>
                                                                            <p>
                                                                              ●教育培训成本课程与课时数量巨大
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>02</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:2.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >"慧眼"模教育仿真实训系统</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:3.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p>
                                                                              ●支持不同教学设备
                                                                            </p>
                                                                            <p>
                                                                              ●教育内容三维具象展示,高效直观
                                                                            </p>
                                                                            <p>
                                                                              ●支持多人协同教学,高效教学指导
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 0px 0px
                                                              10px;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                              style="
                                                                text-align: left;
                                                                margin: 0px 0%;
                                                                justify-content: flex-start;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  min-width: 10%;
                                                                  max-width: 100%;
                                                                  vertical-align: top;
                                                                  transform: matrix(
                                                                    1,
                                                                    0,
                                                                    -0.2,
                                                                    1,
                                                                    0,
                                                                    0
                                                                  );
                                                                  border-style: none
                                                                    none none
                                                                    solid;
                                                                  border-width: 1px
                                                                    5px 1px 0px;
                                                                  border-color: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    rgb(
                                                                      92,
                                                                      107,
                                                                      192
                                                                    )
                                                                    rgb(
                                                                      223,
                                                                      46,
                                                                      0
                                                                    );
                                                                  padding: 5px
                                                                    10px;
                                                                  background-color: rgb(
                                                                    113,
                                                                    197,
                                                                    232
                                                                  );
                                                                  box-shadow: rgba(
                                                                      255,
                                                                      255,
                                                                      255,
                                                                      0
                                                                    )
                                                                    0px 0px 0px;
                                                                  line-height: 1;
                                                                  letter-spacing: 0px;
                                                                  width: auto;
                                                                  height: auto;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style=""
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        text-align: justify;
                                                                        color: rgb(
                                                                          255,
                                                                          255,
                                                                          255
                                                                        );
                                                                      "
                                                                      tn-cell-type="text"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                      title=""
                                                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col1:0.col1:0.txt1"
                                                                    >
                                                                      <p>03</p>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            vertical-align: top;
                                                            width: auto;
                                                            min-width: 10%;
                                                            max-width: 100%;
                                                            height: auto;
                                                            border-top: 1px
                                                              solid
                                                              rgb(113, 197, 232);
                                                            border-top-left-radius: 0px;
                                                            padding: 0px 10px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col2"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style=""
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col2:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  text-align: justify;
                                                                  color: rgb(
                                                                    70,
                                                                    119,
                                                                    197
                                                                  );
                                                                "
                                                                tn-cell-type="text"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                title=""
                                                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:4.col2:0.txt1"
                                                              >
                                                                <p>
                                                                  <strong
                                                                    >核心价值</strong
                                                                  >
                                                                </p>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style="position: static"
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            display: inline-block;
                                                            width: 100%;
                                                            vertical-align: top;
                                                            padding: 0px 0px 0px
                                                              16px;
                                                          "
                                                          tn-cell-type="group"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5.col1"
                                                        >
                                                          <div
                                                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                            tn-uuid=""
                                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                            style="
                                                              position: static;
                                                            "
                                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5.col1:0"
                                                          >
                                                            <section
                                                              class="tn-comp-pin tn-comp-style-pin"
                                                              tn-link="compAttr.link || comp.link"
                                                            >
                                                              <div
                                                                style="
                                                                  display: inline-block;
                                                                  width: 100%;
                                                                  vertical-align: top;
                                                                  border-left: 1px
                                                                    solid
                                                                    rgb(
                                                                      163,
                                                                      202,
                                                                      241
                                                                    );
                                                                  border-bottom-left-radius: 0px;
                                                                  padding: 15px
                                                                    0px 15px
                                                                    10px;
                                                                "
                                                                tn-cell-type="group"
                                                                tn-link="cell.link"
                                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                tn-animate-on-self="true"
                                                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5.col1:0.col1"
                                                              >
                                                                <div
                                                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                  tn-uuid=""
                                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                  style="
                                                                    position: static;
                                                                  "
                                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0"
                                                                >
                                                                  <section
                                                                    class="tn-comp-pin tn-comp-style-pin"
                                                                    tn-link="compAttr.link || comp.link"
                                                                  >
                                                                    <div
                                                                      style="
                                                                        display: inline-block;
                                                                        width: 100%;
                                                                        vertical-align: top;
                                                                        background-color: rgba(
                                                                          139,
                                                                          162,
                                                                          210,
                                                                          0.11
                                                                        );
                                                                        padding: 10px;
                                                                      "
                                                                      tn-cell-type="group"
                                                                      tn-link="cell.link"
                                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                      tn-animate-on-self="true"
                                                                      class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1"
                                                                    >
                                                                      <div
                                                                        class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                                        tn-uuid=""
                                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                                        style=""
                                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1:0"
                                                                      >
                                                                        <section
                                                                          class="tn-comp-pin tn-comp-style-pin"
                                                                          tn-link="compAttr.link || comp.link"
                                                                        >
                                                                          <div
                                                                            style="
                                                                              text-align: justify;
                                                                              font-size: 87.5%;
                                                                              line-height: 1.8;
                                                                              letter-spacing: 1.6px;
                                                                              padding: 0px;
                                                                              color: rgb(
                                                                                37,
                                                                                93,
                                                                                152
                                                                              );
                                                                            "
                                                                            tn-cell-type="text"
                                                                            tn-link="cell.link"
                                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                            tn-animate-on-self="true"
                                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                            title=""
                                                                            class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:5.col1:0.col1:0.col1:0.txt1"
                                                                          >
                                                                            <p>
                                                                              ●<span
                                                                                style="
                                                                                  letter-spacing: 1.6px;
                                                                                "
                                                                                >提高教学培训质量和效率</span
                                                                              >
                                                                            </p>
                                                                            <p>
                                                                              ●释放教师重复机械授课方式
                                                                            </p>
                                                                            <p>
                                                                              ●启发学生学习主动性与实操性
                                                                            </p>
                                                                            <p>
                                                                              ●缓解地域性教学课时资源不平衡
                                                                            </p>
                                                                          </div>
                                                                        </section>
                                                                      </div>
                                                                    </div>
                                                                  </section>
                                                                </div>
                                                              </div>
                                                            </section>
                                                          </div>
                                                        </div>
                                                      </section>
                                                    </div>
                                                    <div
                                                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style=""
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:6"
                                                    >
                                                      <section
                                                        class="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link || comp.link"
                                                      >
                                                        <div
                                                          style="
                                                            text-align: justify;
                                                          "
                                                          tn-cell-type="text"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                          title=""
                                                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.col1:0.col1:4.col1:6.txt1"
                                                        >
                                                          <p><br /></p>
                                                        </div>
                                                      </section>
                                                    </div>
                                                  </div>
                                                </section>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="
                                      position: static;
                                      transform: rotateZ(346deg);
                                    "
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:3"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        text-align: right;
                                        margin: -30px 0px;
                                        justify-content: flex-end;
                                        transform: translate3d(-10px, 0px, 0px);
                                      "
                                    >
                                      <div
                                        tn-cell-type="image"
                                        style="
                                          max-width: 100%;
                                          vertical-align: middle;
                                          display: inline-block;
                                          line-height: 0;
                                          width: 20%;
                                          height: auto;
                                        "
                                        class="tn-cell tn-cell-image tn-state-active ng-scope"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        title=""
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:3.img1"
                                        tn-image="cell"
                                        tn-image-usage="foreground"
                                        opera-tn-image="cell"
                                        opera-tn-image-usage="foreground"
                                      >
                                        <svg
                                          class="tn-image-presenter svg-non-expand"
                                          preserveAspectRatio="none"
                                          width="100%"
                                          height="100%"
                                          viewBox="0 0 222 150"
                                          style="
                                            vertical-align: middle;
                                            max-width: 100%;
                                            width: 100%;
                                          "
                                        >
                                          <image
                                            class="tn-clip-content"
                                            xlink:href="//cdn.yueshare.cn/mlab/0023098870c2dab7542827db355809cf-sz_1086.svg?_tn_usage_=tn-image"
                                            width="100%"
                                            height="100%"
                                          ></image>
                                        </svg>
                                      </div>
                                    </section>
                                  </div>
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style=""
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:4"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                    >
                                      <div
                                        style="text-align: justify"
                                        tn-cell-type="text"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                        title=""
                                        class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:4.txt1"
                                      >
                                        <p><br /></p>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="text-align: justify"
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.txt1"
                                >
                                  <p><br /></p>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style="position: static"
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                                style="
                                  display: flex;
                                  flex-flow: row nowrap;
                                  margin: 15px 0% 10px;
                                "
                              >
                                <div
                                  style="
                                    display: inline-block;
                                    width: auto;
                                    vertical-align: top;
                                    background-color: rgba(229, 232, 253, 0.41);
                                    flex: 100 100 0%;
                                    align-self: flex-start;
                                    height: auto;
                                    line-height: 0;
                                    padding: 0px;
                                  "
                                  tn-cell-type="group"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1"
                                >
                                  <div
                                    class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                    tn-uuid=""
                                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                    style="position: static"
                                    tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:0"
                                  >
                                    <section
                                      class="tn-comp-pin tn-comp-style-pin"
                                      tn-link="compAttr.link || comp.link"
                                      style="
                                        display: flex;
                                        flex-flow: row nowrap;
                                      "
                                    >
                                      <div
                                        style="
                                          display: inline-block;
                                          vertical-align: top;
                                          width: auto;
                                          flex: 100 100 0%;
                                          height: auto;
                                        "
                                        tn-cell-type="group"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col1"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style="position: static"
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col1:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="margin: 0px 0% 8px"
                                          >
                                            <div
                                              tn-cell-type="shape"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              class="tn-cell tn-cell-shape tn-state-active ng-scope"
                                              style="
                                                background-color: rgb(
                                                  0,
                                                  133,
                                                  202
                                                );
                                                height: 1px;
                                              "
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col1:0.line1"
                                            ></div>
                                          </section>
                                        </div>
                                      </div>
                                      <div
                                        style="
                                          display: inline-block;
                                          vertical-align: top;
                                          width: auto;
                                          flex: 0 0 auto;
                                          align-self: flex-start;
                                          min-width: 10%;
                                          max-width: 100%;
                                          height: auto;
                                        "
                                        tn-cell-type="group"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col2"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style=""
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col2:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="
                                              transform: translate3d(
                                                2px,
                                                0px,
                                                0px
                                              );
                                            "
                                          >
                                            <div
                                              style="
                                                text-align: justify;
                                                font-size: 75%;
                                                color: rgb(0, 133, 202);
                                                line-height: 0;
                                                letter-spacing: 5px;
                                                padding: 0px 10px;
                                              "
                                              tn-cell-type="text"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                              title=""
                                              class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col2:0.txt1"
                                            >
                                              <p>END</p>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                      <div
                                        style="
                                          display: inline-block;
                                          vertical-align: top;
                                          width: auto;
                                          flex: 100 100 0%;
                                          height: auto;
                                        "
                                        tn-cell-type="group"
                                        tn-link="cell.link"
                                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                        tn-animate-on-self="true"
                                        class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                        tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col3"
                                      >
                                        <div
                                          class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                          tn-uuid=""
                                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                          style="position: static"
                                          tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col3:0"
                                        >
                                          <section
                                            class="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link || comp.link"
                                            style="margin: 0px 0% 8px"
                                          >
                                            <div
                                              tn-cell-type="shape"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              class="tn-cell tn-cell-shape tn-state-active ng-scope"
                                              style="
                                                background-color: rgb(
                                                  0,
                                                  133,
                                                  202
                                                );
                                                height: 1px;
                                              "
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col3:0.line1"
                                            ></div>
                                          </section>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div
                              class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                              tn-uuid=""
                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                              style=""
                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:17"
                            >
                              <section
                                class="tn-comp-pin tn-comp-style-pin"
                                tn-link="compAttr.link || comp.link"
                              >
                                <div
                                  style="text-align: justify"
                                  tn-cell-type="text"
                                  tn-link="cell.link"
                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                  tn-animate-on-self="true"
                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                  title=""
                                  class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:17.txt1"
                                >
                                  <p><br /></p>
                                </div>
                              </section>
                            </div>
                          </div>
                        </section>
                      </div>
                      <!-- end ngRepeat: chd in cellGroupItems track by $index -->
                    </div>
                  </section>
                </div>
                <!-- end ngRepeat: chd in cellGroupItems track by $index -->
              </div>
            </section>
          </div>
          <!-- end ngRepeat: chd in cellGroupItems track by $index -->
        </div>
        <div
          class="tn-popup-slot tn-view-popups tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute group-empty tn-state-active"
          tn-cell-type="group"
          tn-cell="popups"
          tn-link="cell.link"
          ng-style="cell.style | optimizeImageType"
          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
          tn-animate-on-self="true"
          tn-sound="cell.sound"
          ng-class="{ 'group-empty': !(cellGroupItems.length) }"
          opera-tn-ra-cell="box.popups"
        >
          <!-- ngRepeat: chd in cellGroupItems track by $index -->
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  name: "kdAbout",
  mounted() {
    this.$emit("getMessage", false);
  },
};
</script>

<style lang="less" scoped >
.article {
  background-color: rgb(185, 217, 235);
  padding-top: 20px;
}
</style>
